import { render, staticRenderFns } from "./List1.vue?vue&type=template&id=154261b8&scoped=true&a=2"
import script from "./List1.vue?vue&type=script&lang=js&a=2"
export * from "./List1.vue?vue&type=script&lang=js&a=2"
import style0 from "./List1.vue?vue&type=style&index=0&id=154261b8&scoped=true&lang=scss&a=2"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "154261b8",
  null
  
)

export default component.exports